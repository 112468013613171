export default {
  pathName: [
    {
      eng: "/",
      cn: [],
    },
    {
      eng: "/Index",
      cn: [
        {
          text: "首頁",
          link: "",
        },
      ],
    },
    {
      eng: "/EmployeeList",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeEdit",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeAdd",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeView",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeWorkflowItemView",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeWorkflowItemEdit",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeLeaveApply",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeLeaveApplyList",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/Clock",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "打卡",
          link: "",
        },
      ],
    },
    {
      eng: "/CompensateList",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "補卡",
          link: "/CompensateList",
        },
      ],
    },
    {
      eng: "/Compensate",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "補卡",
          link: "/CompensateList",
        },
      ],
    },
    {
      eng: "/CompensateView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "補卡",
          link: "/CompensateList",
        },
      ],
    },
    {
      eng: "/PersonalAttendance",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "個人出勤",
          link: "/PersonalAttendance",
        },
      ],
    },
    {
      eng: "/AllAttendance",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "全體出勤",
          link: "/AllAttendance",
        },
      ],
    },
    // 系統設定
    {
      eng: "/WorkflowList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核流程管理",
          link: "/WorkflowList",
        },
      ],
    },
    {
      eng: "/WorkflowEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核流程管理",
          link: "/WorkflowList",
        },
      ],
    },
    {
      eng: "/CompanyList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公司資訊管理",
          link: "/CompanyList",
        },
      ],
    },
    {
      eng: "/CompanyAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公司資訊管理",
          link: "/CompanyList",
        },
      ],
    },
    {
      eng: "/CompanyView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公司資訊管理",
          link: "/CompanyList",
        },
      ],
    },
    {
      eng: "/CompanyEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公司資訊管理",
          link: "/CompanyList",
        },
      ],
    },
    {
      eng: "/Permission",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "權限管理",
          link: "/Permission",
        },
      ],
    },
    {
      eng: "/PermissionAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "權限管理",
          link: "/Permission",
        },
      ],
    },
    {
      eng: "/PermissionEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "權限管理",
          link: "/Permission",
        },
      ],
    },
    {
      eng: "/PermissionView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "權限管理",
          link: "/Permission",
        },
      ],
    },
    {
      eng: "/PermissionEmployee",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "權限管理",
          link: "/Permission",
        },
      ],
    },
    {
      eng: "/PermissionEmployeeEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "權限管理",
          link: "/Permission",
        },
      ],
    },
    {
      eng: "/FlowRoleList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核角色管理",
          link: "/FlowRoleList",
        },
      ],
    },
    {
      eng: "/FlowRoleEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核角色管理",
          link: "/FlowRoleList",
        },
      ],
    },
    {
      eng: "/FlowRoleAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核角色管理",
          link: "/FlowRoleList",
        },
      ],
    },
    {
      eng: "/Calendar",
      cn: [
        {
          text: "Console",
          link: "",
        },
        {
          text: "行事曆管理",
          link: "/Calendar",
        },
      ],
    },
    {
      eng: "/WorkflowType",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核類別管理",
          link: "/WorkflowType",
        },
      ],
    },
    {
      eng: "/WorkflowTypeEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "簽核類別管理",
          link: "/WorkflowType",
        },
      ],
    },
    {
      eng: "/MeetingRoomList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "會議室管理",
          link: "/MeetingRoomList",
        },
      ],
    },
    {
      eng: "/MeetingRoomAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "會議室管理",
          link: "/MeetingRoomAdd",
        },
      ],
    },
    {
      eng: "/MeetingRoomView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "會議室管理",
          link: "/MeetingRoomView",
        },
      ],
    },
    {
      eng: "/MeetingRoomEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "會議室管理",
          link: "/MeetingRoomEdit",
        },
      ],
    },
    // 簽核
    {
      eng: "/WorkflowItemList",
      cn: [
        {
          text: "簽核",
          link: "",
        },
        {
          text: "簽核列表",
          link: "",
        },
      ],
    },
    {
      eng: "/WorkflowItemListView",
      cn: [
        {
          text: "簽核",
          link: "",
        },
        {
          text: "所有簽核檢視",
          link: "",
        },
      ],
    },
    {
      eng: "/WorkflowItem_Substitute",
      cn: [
        {
          text: "簽核",
          link: "",
        },
        {
          text: "代理人簽核列表",
          link: "/WorkflowItem_Substitute",
        },
      ],
    },
    {
      eng: "/WorkflowItem_Rent",
      cn: [
        {
          text: "簽核",
          link: "",
        },
        {
          text: "租借簽核",
          link: "/WorkflowItem_Rent",
        },
      ],
    },
    {
      eng: "/CheckInList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInHistoryList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInHistoryAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInHistoryCopy",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInHistoryEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInHistoryView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInEmployeeEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/CheckInView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "打卡規則管理",
          link: "/CheckInList",
        },
      ],
    },
    {
      eng: "/DayOffList",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "請假",
          link: "/DayOffList",
        },
      ],
    },
    {
      eng: "/DayOffAdd",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "請假",
          link: "/DayOffList",
        },
      ],
    },
    {
      eng: "/DayOffView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "請假",
          link: "/DayOffList",
        },
      ],
    },
    {
      eng: "/DayOffCancel",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "請假",
          link: "/DayOffList",
        },
      ],
    },
    {
      eng: "/DayOffCancelView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "請假",
          link: "/DayOffList",
        },
      ],
    },
    {
      eng: "/BusinessTripList",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "外出",
          link: "/BusinessTripList",
        },
      ],
    },
    {
      eng: "/BusinessTripAdd",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "外出",
          link: "/BusinessTripList",
        },
      ],
    },
    {
      eng: "/BusinessTripView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "外出",
          link: "/BusinessTripList",
        },
      ],
    },
    {
      eng: "/BusinessTripCancel",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "外出",
          link: "/BusinessTripList",
        },
      ],
    },
    {
      eng: "/BusinessTripCancelView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "外出",
          link: "/BusinessTripList",
        },
      ],
    },
    {
      eng: "/DepartmentList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "部門管理",
          link: "/DepartmentList",
        },
      ],
    },
    {
      eng: "/DepartmentEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "部門管理",
          link: "/DepartmentList",
        },
      ],
    },
    {
      eng: "/OvertimeList",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "加班",
          link: "/OvertimeList",
        },
      ],
    },
    {
      eng: "/OvertimeAdd",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "加班",
          link: "/OvertimeList",
        },
      ],
    },
    {
      eng: "/OvertimeView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "加班",
          link: "/OvertimeList",
        },
      ],
    },
    {
      eng: "/OvertimeCancel",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "加班",
          link: "/OvertimeList",
        },
      ],
    },
    {
      eng: "/OvertimeCancelView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "加班",
          link: "/OvertimeList",
        },
      ],
    },
    {
      eng: "/Parameter",
      cn: [
        {
          text: "Console",
          link: "",
        },
        {
          text: "參數管理",
          link: "/Parameter",
        },
      ],
    },
    {
      eng: "/ParameterConsole",
      cn: [
        {
          text: "Console",
          link: "",
        },
        {
          text: "參數管理",
          link: "/Parameter",
        },
      ],
    },
    {
      eng: "/HolidayView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "排休",
          link: "/HolidayView",
        },
      ],
    },
    {
      eng: "/HolidayEdit",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "排休",
          link: "/HolidayView",
        },
      ],
    },
    {
      eng: "/HolidayWorkflowItemView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "排休",
          link: "/HolidayView",
        },
      ],
    },
    {
      eng: "/HolidayConsoleView",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "公司行事曆",
          link: "/HolidayConsoleView",
        },
      ],
    },
    {
      eng: "/HolidayConsoleEdit",
      cn: [
        {
          text: "差勤管理",
          link: "",
        },
        {
          text: "公司行事曆",
          link: "/HolidayConsoleView",
        },
      ],
    },
    {
      eng: "/LeaveList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "假別管理",
          link: "/LeaveList",
        },
      ],
    },
    {
      eng: "/LeaveAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "假別管理",
          link: "/LeaveList",
        },
      ],
    },
    {
      eng: "/LeaveView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "假別管理",
          link: "/LeaveList",
        },
      ],
    },
    {
      eng: "/LeaveEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "假別管理",
          link: "/LeaveList",
        },
      ],
    },
    {
      eng: "/LeaveHistory",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "假別管理",
          link: "/LeaveList",
        },
      ],
    },
    {
      eng: "/EmployeeLeaveList",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeLeaveEdit",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/EmployeeLeaveAdd",
      cn: [
        {
          text: "人事管理",
          link: "",
        },
        {
          text: "職員帳號管理",
          link: "/EmployeeList",
        },
      ],
    },
    {
      eng: "/PayrollList",
      cn: [
        {
          text: "薪資管理",
          link: "",
        },
        {
          text: "薪資條管理",
          link: "/PayrollList",
        },
      ],
    },
    {
      eng: "/PayrollView",
      cn: [
        {
          text: "薪資管理",
          link: "",
        },
        {
          text: "薪資條管理",
          link: "/PayrollList",
        },
      ],
    },
    {
      eng: "/PasswordChange",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "修改密碼",
          link: "/PasswordChange",
        },
      ],
    },
    {
      eng: "/SalaryList",
      cn: [
        {
          text: "薪資管理",
          link: "",
        },
        {
          text: "薪調紀錄",
          link: "/SalaryList",
        },
      ],
    },
    {
      eng: "/OptionList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "選項管理",
          link: "/OptionList",
        },
      ],
    },
    {
      eng: "/SalaryAdjustList",
      cn: [
        {
          text: "薪資管理",
          link: "",
        },
        {
          text: "薪調紀錄",
          link: "/SalaryList",
        },
      ],
    },
    {
      eng: "/OptionAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "選項管理",
          link: "/OptionList",
        },
      ],
    },
    {
      eng: "/OptionEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "選項管理",
          link: "/OptionList",
        },
      ],
    },
    {
      eng: "/SalarySetting",
      cn: [
        {
          text: "薪資管理",
          link: "",
        },
        {
          text: "薪調紀錄",
          link: "/SalaryList",
        },
      ],
    },
    {
      eng: "/SalarySettingEdit",
      cn: [
        {
          text: "薪資管理",
          link: "",
        },
        {
          text: "薪調紀錄",
          link: "/SalaryList",
        },
      ],
    },
    {
      eng: "/Announcement",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公告訊息",
          link: "/Announcement",
        },
      ],
    },
    {
      eng: "/AnnouncementAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公告訊息",
          link: "/Announcement",
        },
      ],
    },
    {
      eng: "/AnnouncementEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公告訊息",
          link: "/Announcement",
        },
      ],
    },
    {
      eng: "/AnnouncementView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "公告訊息",
          link: "/Announcement",
        },
      ],
    },
    {
      eng: "/WorkflowRecord",
      cn: [
        {
          text: "通知訊息列表",
          link: "/WorkflowRecord",
        },
      ],
    },
    {
      eng: "/EmployeeForLogin",
      cn: [
        {
          text: "職員基本資訊",
          link: "/EmployeeForLogin",
        },
      ],
    },
    {
      eng: "/SubscriptionList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "訂閱管理",
          link: "/SubscriptionList",
        },
      ],
    },
    {
      eng: "/SubscriptionView",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "訂閱管理",
          link: "/SubscriptionList",
        },
      ],
    },
    {
      eng: "/DealerManagementList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "經銷商管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerManagementEdit",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "經銷商管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerManagementAdd",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "經銷商管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerBookingList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "客戶名單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerBookingView",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "客戶名單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/ContractManagementList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "合約管理",
          link: "",
        },
      ],
    },
    {
      eng: "/ContractManagementAdd",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "合約管理",
          link: "",
        },
      ],
    },
    {
      eng: "/ContractManagementEdit",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "合約管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerWorkflowList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "申請單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerWorkflowEdit",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "申請單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerShipmentList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "出貨管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerShipmentEdit",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "出貨管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerShipmentAdd",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "出貨管理",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerAnnouncementList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "最新消息",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerAnnouncementEdit",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "最新消息",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerAnnouncementAdd",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "最新消息",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerDownloadList",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "下載專區",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerDownloadEdit",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "下載專區",
          link: "",
        },
      ],
    },
    {
      eng: "/DealerDownloadAdd",
      cn: [
        {
          text: "經銷商管理系統",
          link: "",
        },
        {
          text: "下載專區",
          link: "",
        },
      ],
    },
    {
      eng: "/CarList",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "車型管理",
          link: "/CarList",
        },
      ],
    },
    {
      eng: "/CarAdd",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "車型管理",
          link: "/CarAdd",
        },
      ],
    },
    {
      eng: "/CarEdit",
      cn: [
        {
          text: "系統設定",
          link: "",
        },
        {
          text: "車型管理",
          link: "/CarEdit",
        },
      ],
    },
    {
      eng: "/CarRentList",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "借還登記",
          link: "/CarRentList",
        },
      ],
    },
    {
      eng: "/CarRentInsetList",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "借還登記",
          link: "/CarRentInsetList",
        },
      ],
    },
    {
      eng: "/CarRentAdd",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "借還登記",
          link: "/CarRentAdd",
        },
      ],
    },
    {
      eng: "/CarRentDrop",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "借還登記",
          link: "/CarRentDrop",
        },
      ],
    },
    {
      eng: "/CarRentView",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "借還登記",
          link: "",
        },
      ],
    },
    {
      eng: "/CarRentView_Cancel",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "借還登記",
          link: "",
        },
      ],
    },
    {
      eng: "/CarUsingList",
      cn: [
        {
          text: "車用管理",
          link: "",
        },
        {
          text: "車用狀況",
          link: "",
        },
      ],
    },
    {
      eng: "/MeetingList",
      cn: [
        {
          text: "會議室租借",
          link: "",
        },
        {
          text: "租借登記",
          link: "/MeetingList",
        },
      ],
    },
    {
      eng: "/MeetingInsetList",
      cn: [
        {
          text: "會議室租借",
          link: "",
        },
        {
          text: "租借登記",
          link: "/MeetingList",
        },
      ],
    },
    {
      eng: "/MeetingAdd",
      cn: [
        {
          text: "會議室租借",
          link: "",
        },
        {
          text: "租借登記",
          link: "/MeetingList",
        },
      ],
    },
    {
      eng: "/MeetingView",
      cn: [
        {
          text: "會議室租借",
          link: "",
        },
        {
          text: "租借登記",
          link: "/MeetingList",
        },
      ],
    },
    // 惠加進銷存 ---------
    // 惠加進銷存 - 派工單管理
    {
      eng: "/HJ_StockMgmt/WorkOrder/WorkOrderList",
      cn: [
        {
          text: "派工單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/WorkOrder/WorkOrderAdd",
      cn: [
        {
          text: "派工單管理",
          link: "",
        },
        {
          text: "派工單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/WorkOrder/WorkOrderEdit",
      cn: [
        {
          text: "派工單管理",
          link: "",
        },
        {
          text: "派工單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/WorkOrder/WorkOrderView",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "派工單",
          link: "",
        },
      ],
    },
    // 惠加進銷存 - 訂單管理
    {
      eng: "/HJ_StockMgmt/Order/OrderList",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/ProductionPlanEdit",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "生產進度計畫表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/ProductionPlanView",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "生產進度計畫表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/OrderControlAdd",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "客戶訂單管制表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/OrderControlEdit",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "客戶訂單管制表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/OrderControlView",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "客戶訂單管制表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/ContractReviewView",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "合約審查表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Order/ContractReviewEdit",
      cn: [
        {
          text: "訂單管理",
          link: "",
        },
        {
          text: "合約審查表",
          link: "",
        },
      ],
    },
    // 惠加進銷存 - 進料管理
    {
      eng: "/HJ_StockMgmt/Incoming/IncomingList",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/MaterialInspectionAdd",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "進料檢驗紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/MaterialInspectionEdit",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "進料檢驗紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/MaterialInspectionView",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "進料檢驗紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/PurchaseOrderAdd",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "採購單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/PurchaseOrderEdit",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "採購單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/PurchaseOrderView",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "採購單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/OutsourceOrderAdd",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "委外加工單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/OutsourceOrderEdit",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "委外加工單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Incoming/OutsourceOrderView",
      cn: [
        {
          text: "進料管理",
          link: "",
        },
        {
          text: "委外加工單",
          link: "",
        },
      ],
    },
    // 惠加進銷存 - 進料管理
    {
      eng: "/HJ_StockMgmt/Warehouse/WarehouseList",
      cn: [
        {
          text: "倉儲管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Warehouse/RawMaterialAdd",
      cn: [
        {
          text: "倉儲管理",
          link: "",
        },
        {
          text: "成品庫存卡",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Warehouse/RawMaterialView",
      cn: [
        {
          text: "倉儲管理",
          link: "",
        },
        {
          text: "成品庫存卡",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Warehouse/FinishedInventoryAdd",
      cn: [
        {
          text: "倉儲管理",
          link: "",
        },
        {
          text: "成品庫存卡",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Warehouse/FinishedInventoryView",
      cn: [
        {
          text: "倉儲管理",
          link: "",
        },
        {
          text: "成品庫存卡",
          link: "",
        },
      ],
    },
    // 惠加進銷存 - 系統管理
    {
      eng: "/HJ_StockMgmt/System/SystemList",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/MaterialMgmtAdd",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "物料管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/MaterialMgmtEdit",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "物料管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/MaterialMgmtView",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "物料管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/SupplierMgmtAdd",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "供應商管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/SupplierMgmtView",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "供應商管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/PersonnelMgmtAdd",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "人員管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/PersonnelMgmtEdit",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "人員管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/FormMgmtAdd",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "表單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/System/FormMgmtEdit",
      cn: [
        {
          text: "系統管理",
          link: "",
        },
        {
          text: "表單管理",
          link: "",
        },
      ],
    },
    // 惠加進銷存 - 檢驗紀錄管理
    {
      eng: "/HJ_StockMgmt/Inspection/MachineTestList",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/MachineTestEdit",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "整機測試紀錄表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/MachineTestView",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "整機測試紀錄表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/PerformanceReportEdit",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "整機性能指標檢測報告",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/PerformanceReportView",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "整機性能指標檢測報告",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/FinalInspectionEdit",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "最終檢驗紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/FinalInspectionView",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "最終檢驗紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/LeakageTestEdit",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "漏電流測試表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Inspection/LeakageTestView",
      cn: [
        {
          text: "檢驗紀錄管理",
          link: "",
        },
        {
          text: "漏電流測試表",
          link: "",
        },
      ],
    },
    // 惠加進銷存 - 出貨單管理
    {
      eng: "/HJ_StockMgmt/Delivery/DeliveryList",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/DeliveryNoteAdd",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "出貨單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/DeliveryNoteEdit",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "出貨單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/DeliveryNoteView",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "出貨單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/InstallationRecordEdit",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "安裝紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/InstallationRecordView",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "安裝紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/BatchRecordEdit",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "生產批次紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/BatchRecordView",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "生產批次紀錄單",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/MaintenanceRecordList",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "客戶維修服務紀錄表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/MaintenanceRecordEdit",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "客戶維修服務紀錄表",
          link: "",
        },
      ],
    },
    {
      eng: "/HJ_StockMgmt/Delivery/MaintenanceRecordView",
      cn: [
        {
          text: "出貨單管理",
          link: "",
        },
        {
          text: "客戶維修服務紀錄表",
          link: "",
        },
      ],
    },
  ],
};

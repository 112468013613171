import { reactive, computed, ref, watch } from "@vue/reactivity";
import { tooltipLight } from "naive-ui/es/tooltip/styles";
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import pathData from "./path.js";
// import { routeInfo } from "@/assets/js/common";

// route permission --------
const isDevMode = process.env.VUE_APP_MODE === "dev";

const routeInfo = computed(() => {
  const raw = JSON.parse(localStorage.getItem("route"));

  const sorted = [];

  raw.map((item) => {
    sorted.push(item);

    if (!!item.subMenus.length) {
      item.subMenus.map((sub) => sorted.push(sub));
    }
  });

  return sorted;
});

function toNotFound404(to, from, next) {
  next({ name: "NotFound", params: { pathMatch: to.fullPath.slice(1) } });
}

const showHeadName = (path) => {
  const findPath = pathData.pathName.find((item) => item.eng === "/" + path);
  if (findPath) {
    document.title = `YUNIN X ERP - ${
      findPath.cn[findPath.cn.length - 1].text
    }`;
  } else {
    document.title = "YUNIN X ERP";
  }
};

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: { name: "Login" },
    },
    {
      path: "/Repair",
      name: "Repair",
      component: () => import("@/views/repair.vue"),
    },
    {
      // 開發暫時固定公司名
      path: "/:companyName/Login",
      // path: "/CyberCloud/Login",
      name: "Login",
      component: () => import("@/views/login.vue"),
    },
    {
      path: "/:companyName/Forgot",
      name: "Forgot",
      component: () => import("@/views/forgot.vue"),
    },
    {
      path: "/ChangePassword/:verifyCode/:enterpriseId/:companyName",
      name: "ChangePassword",
      component: () => import("@/views/changePassword.vue"),
    },
    {
      path: "/",
      name: "Layout",
      component: () => import("@/views/layout.vue"),
      children: [
        {
          path: "/Index",
          name: "Index",
          component: () => import("@/views/Index.vue"),
          props: { title: "Dash Board" },
          beforeEnter: (to, from, next) => {
            showHeadName(to.name);
            next();
          },
        },
        {
          path: "/EmployeeList",
          name: "EmployeeList",
          component: () => import("@/views/Employee/EmployeeList.vue"),
          props: { title: "職員帳號管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeEdit/:employeeCode",
          name: "EmployeeEdit",
          component: () => import("@/views/Employee/EmployeeEdit&View.vue"),
          props: { title: "編輯職員" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeAdd",
          name: "EmployeeAdd",
          component: () => import("@/views/Employee/EmployeeAdd.vue"),
          props: { title: "新增職員" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeView/:employeeCode",
          name: "EmployeeView",
          component: () => import("@/views/Employee/EmployeeEdit&View.vue"),
          props: { title: "職員基本資訊" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeWorkflowItemView/:applyId/:itemId/:companyId/:fromWorkflow?",
          name: "EmployeeWorkflowItemView",
          component: () =>
            import("@/views/Employee/EmployeeWorkflowItemView.vue"),
          props: { title: "職員基本資訊" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeWorkflowItemEdit/:applyId/:itemId",
          name: "EmployeeWorkflowItemEdit",
          component: () =>
            import("@/views/Employee/EmployeeWorkflowItemView.vue"),
          props: { title: "編輯職員" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeLeaveList/:employeeCode",
          name: "EmployeeLeaveList",
          component: () => import("@/views/Employee/EmployeeLeaveList.vue"),
          props: { title: "休假管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeLeaveEdit/:employeeCode/:employeeLeaveId",
          name: "EmployeeLeaveEdit",
          component: () => import("@/views/Employee/EmployeeLeaveEdit.vue"),
          props: { title: "編輯休假" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeLeaveAdd/:employeeCode",
          name: "EmployeeLeaveAdd",
          component: () => import("@/views/Employee/EmployeeLeaveAdd.vue"),
          props: { title: "編輯休假" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeLeaveApply/:companyId/:employeeId/:gender",
          name: "EmployeeLeaveApply",
          component: () => import("@/views/Employee/EmployeeLeaveApply.vue"),
          props: { title: "補申請假單" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/EmployeeLeaveApplyList/:companyId/:employeeId/:gender",
          name: "EmployeeLeaveApplyList",
          component: () =>
            import("@/views/Employee/EmployeeLeaveApplyList.vue"),
          props: { title: "補申請請假紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "EmployeeList"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PersonalAttendance",
          name: "PersonalAttendance",
          component: () => import("@/views/Attendance/PersonalAttendance.vue"),
          props: { title: "個人出勤紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "PersonalAttendance"
            );
            showHeadName(to.name);

            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/AllAttendance",
          name: "AllAttendance",
          component: () => import("@/views/Attendance/AllAttendance.vue"),
          props: { title: "全體出勤紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "AllAttendance"
            );
            showHeadName(to.name);

            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CompanyList",
          name: "CompanyList",
          component: () => import("@/views/Company/CompanyList.vue"),
          props: { title: "公司資訊管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompanyList"
            );
            showHeadName(to.name);

            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CompanyAdd",
          name: "CompanyAdd",
          component: () => import("@/views/Company/CompanyAdd.vue"),
          props: { title: "新增公司資料" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompanyList"
            );
            showHeadName(to.name);

            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CompanyEdit/:companyCode",
          name: "CompanyEdit",
          component: () => import("@/views/Company/CompanyEdit.vue"),
          props: { title: "編輯公司資料" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompanyList"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CompanyView/:companyCode",
          name: "CompanyView",
          component: () => import("@/views/Company/CompanyView.vue"),
          props: { title: "檢視公司資料" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompanyList"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/Permission",
          name: "Permission",
          component: () => import("@/views/Permission/Permission.vue"),
          props: { title: "權限管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Permission"
            );
            showHeadName(to.name);

            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PermissionAdd",
          name: "PermissionAdd",
          component: () => import("@/views/Permission/PermissionAdd_Edit.vue"),
          props: { title: "新增權限" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Permission"
            );
            showHeadName(to.name);

            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PermissionView/:id",
          name: "PermissionView",
          component: () => import("@/views/Permission/PermissionAdd_Edit.vue"),
          props: { title: "檢視權限" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Permission"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PermissionEdit/:id",
          name: "PermissionEdit",
          component: () => import("@/views/Permission/PermissionAdd_Edit.vue"),
          props: { title: "編輯權限" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Permission"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PermissionEmployee/:id",
          name: "PermissionEmployee",
          component: () => import("@/views/Permission/PermissionEmployee.vue"),
          props: { title: "權限管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Permission"
            );
            showHeadName(to.name);

            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PermissionEmployeeEdit/:id",
          name: "PermissionEmployeeEdit",
          component: () =>
            import("@/views/Permission/PermissionEmployeeEdit.vue"),
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Permission"
            );
            showHeadName(to.name);

            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowList",
          name: "WorkflowList",
          component: () => import("@/views/Workflow/WorkflowList.vue"),
          props: { title: "簽核流程管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "WorkflowList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowEdit/:company:workflow",
          name: "WorkflowEdit",
          component: () => import("@/views/Workflow/WorkflowEdit.vue"),
          props: { title: "簽核流程編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "WorkflowList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowType",
          name: "WorkflowType",
          component: () => import("@/views/Workflow/WorkflowType.vue"),
          props: { title: "簽核類別管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "WorkflowType"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowTypeEdit",
          name: "WorkflowTypeEdit",
          component: () => import("@/views/Workflow/WorkflowType.vue"),
          props: { title: "簽核類別編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "WorkflowType"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowItemList/:workFlowType/:companyId/:isCancel?",
          name: "WorkflowItemList",
          component: () => import("@/views/WorkflowItem/WorkflowItemList.vue"),
          props: { title: "簽核列表" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "WorkflowItemList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowItemListView/:workFlowType/:companyId",
          name: "WorkflowItemListView",
          component: () =>
            import("@/views/WorkflowItem/WorkflowItemListView.vue"),
          props: { title: "所有簽核檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "WorkflowItemListView"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowItem_Substitute",
          name: "WorkflowItem_Substitute",
          component: () =>
            import("@/views/WorkflowItem/WorkflowItem_Substitute.vue"),
          props: { title: "代理人簽核列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "WorkflowItemList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/WorkflowItem_Rent",
          name: "WorkflowItem_Rent",
          component: () => import("@/views/WorkflowItem/WorkflowItem_Rent.vue"),
          props: { title: "租借簽核列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "WorkflowItemList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/FlowRoleList",
          name: "FlowRoleList",
          component: () => import("@/views/FlowRole/FlowRoleList.vue"),
          props: { title: "簽核角色管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "FlowRoleList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/FlowRoleAdd/:company",
          name: "FlowRoleAdd",
          component: () => import("@/views/FlowRole/FlowRoleAdd.vue"),
          props: { title: "新增簽核角色" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "FlowRoleList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/FlowRoleEdit/:company/:flowRoleId",
          name: "FlowRoleEdit",
          component: () => import("@/views/FlowRole/FlowRoleEdit.vue"),
          props: { title: "簽核角色編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "FlowRoleList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/Calendar",
          name: "Calendar",
          component: () => import("@/views/Calendar/Calendar.vue"),
          props: { title: "行事曆管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Calendar"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInList",
          name: "CheckInList",
          component: () => import("@/views/CheckIn/CheckInList.vue"),
          props: { title: "打卡規則管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInAdd",
          name: "CheckInAdd",
          component: () => import("@/views/CheckIn/CheckInSetting.vue"),
          props: { title: "新增打卡時段" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInView/:companyId/:clocktimeId/:clocktimeHistoryId",
          name: "CheckInView",
          component: () => import("@/views/CheckIn/CheckInSetting.vue"),
          props: { title: "打卡時段檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInEdit/:companyId/:clocktimeId/:clocktimeHistoryId",
          name: "CheckInEdit",
          component: () => import("@/views/CheckIn/CheckInSetting.vue"),
          props: { title: "打卡時段編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInEmployeeEdit/:companyId/:clocktimeId",
          name: "CheckInEmployeeEdit",
          component: () => import("@/views/CheckIn/CheckInEmployeeEdit.vue"),
          props: { title: "打卡時段成員編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInHistoryList/:companyId/:clocktimeId",
          name: "CheckInHistoryList",
          component: () => import("@/views/CheckIn/CheckInHistoryList.vue"),
          props: { title: "目前歷程" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        // 歷程紀錄還會需要新增、編輯
        {
          path: "/CheckInHistoryAdd/:companyId/:clocktimeId",
          name: "CheckInHistoryAdd",
          component: () => import("@/views/CheckIn/CheckInHistorySetting.vue"),
          props: { title: "新增歷程" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInHistoryCopy/:companyId/:clocktimeId/:clocktimeHistoryId",
          name: "CheckInHistoryCopy",
          component: () => import("@/views/CheckIn/CheckInHistorySetting.vue"),
          props: { title: "新增歷程" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInHistoryEdit/:companyId/:clocktimeId/:clocktimeHistoryId",
          name: "CheckInHistoryEdit",
          component: () => import("@/views/CheckIn/CheckInHistorySetting.vue"),
          props: { title: "歷程編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CheckInHistoryView/:companyId/:clocktimeId/:clocktimeHistoryId",
          name: "CheckInHistoryView",
          component: () => import("@/views/CheckIn/CheckInHistorySetting.vue"),
          props: { title: "歷程檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CheckInList"
            );
            showHeadName(to.name);
            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/Clock",
          name: "Clock",
          component: () => import("@/views/Clock/Clock.vue"),
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Clock"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CompensateList",
          name: "CompensateList",
          component: () => import("@/views/Clock/CompensateList.vue"),
          props: { title: "補卡紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompensateList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/Compensate/:type",
          name: "Compensate",
          component: () => import("@/views/Clock/Compensate.vue"),
          props: { title: "補卡申請" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompensateList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CompensateView/:itemId/:fromWorkflow?",
          name: "CompensateView",
          component: () => import("@/views/Clock/CompensateView.vue"),
          props: { title: "補卡檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CompensateList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DayOffList",
          name: "DayOffList",
          component: () => import("@/views/DayOff/DayOffList.vue"),
          props: { title: "請假紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DayOffAdd",
          name: "DayOffAdd",
          component: () => import("@/views/DayOff/DayOffAdd.vue"),
          props: { title: "請假申請" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DayOffView/:itemId/:fromWorkflow?",
          name: "DayOffView",
          component: () => import("@/views/DayOff/DayOffView.vue"),
          props: { title: "請假檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DayOffCancel/:itemId/:cancelId",
          name: "DayOffCancel",
          component: () => import("@/views/DayOff/DayOffCancel.vue"),
          props: { title: "請假註銷" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DayOffCancelView/:itemId/:cancelId/:fromWorkflow?",
          name: "DayOffCancelView",
          component: () => import("@/views/DayOff/DayOffCancel.vue"),
          props: { title: "請假註銷" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OvertimeList",
          name: "OvertimeList",
          component: () => import("@/views/Overtime/OvertimeList.vue"),
          props: { title: "加班紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OvertimeAdd",
          name: "OvertimeAdd",
          component: () => import("@/views/Overtime/OvertimeAdd.vue"),
          props: { title: "加班申請" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OvertimeView/:itemId/:fromWorkflow?",
          name: "OvertimeView",
          component: () => import("@/views/Overtime/OvertimeView.vue"),
          props: { title: "加班檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OvertimeCancel/:itemId/:cancelId",
          name: "OvertimeCancel",
          component: () => import("@/views/Overtime/OvertimeCancel.vue"),
          props: { title: "加班註銷" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OvertimeCancelView/:itemId/:cancelId/:fromWorkflow?",
          name: "OvertimeCancelView",
          component: () => import("@/views/Overtime/OvertimeCancel.vue"),
          props: { title: "加班註銷" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DayOffList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/BusinessTripList",
          name: "BusinessTripList",
          component: () => import("@/views/BusinessTrip/BusinessTripList.vue"),
          props: { title: "外出紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "BusinessTripList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/BusinessTripAdd",
          name: "BusinessTripAdd",
          component: () => import("@/views/BusinessTrip/BusinessTripAdd.vue"),
          props: { title: "外出申請" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "BusinessTripList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/BusinessTripView/:itemId/:fromWorkflow?",
          name: "BusinessTripView",
          component: () => import("@/views/BusinessTrip/BusinessTripView.vue"),
          props: { title: "外出檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "BusinessTripList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/BusinessTripCancel/:itemId/:cancelId",
          name: "BusinessTripCancel",
          component: () =>
            import("@/views/BusinessTrip/BusinessTripCancel.vue"),
          props: { title: "外出註銷" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "BusinessTripList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/BusinessTripCancelView/:itemId/:cancelId/:fromWorkflow?",
          name: "BusinessTripCancelView",
          component: () =>
            import("@/views/BusinessTrip/BusinessTripCancel.vue"),
          props: { title: "外出註銷" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "BusinessTripList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DepartmentList",
          name: "DepartmentList",
          component: () => import("@/views/Department/DepartmentList.vue"),
          props: { title: "部門管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DepartmentList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/DepartmentEdit/:companyId",
          name: "DepartmentEdit",
          component: () => import("@/views/Department/DepartmentEdit.vue"),
          props: { title: "部門管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "DepartmentList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/Parameter",
          name: "Parameter",
          component: () => import("@/views/Parameter/Parameter.vue"),
          props: { title: "參數管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Parameter"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/ParameterConsole",
          name: "ParameterConsole",
          component: () => import("@/views/Parameter/ParameterConsole.vue"),
          props: { title: "參數管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Parameter"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/HolidayView",
          name: "HolidayView",
          component: () => import("@/views/Holiday/HolidayView.vue"),
          props: { title: "個人排休檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "HolidayView"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/HolidayEdit",
          name: "HolidayEdit",
          component: () => import("@/views/Holiday/HolidayEdit.vue"),
          props: { title: "個人排休" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "HolidayView"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/HolidayWorkflowItemView/:applyId/:itemId/:fromWorkflow?",
          name: "HolidayWorkflowItemView",
          component: () =>
            import("@/views/Holiday/HolidayWorkflowItemView.vue"),
          props: { title: "個人排休檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "HolidayView"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/HolidayConsoleView",
          name: "HolidayConsoleView",
          component: () => import("@/views/Holiday/HolidayConsoleView.vue"),
          props: { title: "公司行事曆" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "HolidayConsoleView"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/HolidayConsoleEdit/:companyId",
          name: "HolidayConsoleEdit",
          component: () => import("@/views/Holiday/HolidayConsoleEdit.vue"),
          props: { title: "公司行事曆編輯" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "HolidayConsoleView"
            );
            showHeadName(to.name);
            if (target.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/LeaveList",
          name: "LeaveList",
          component: () => import("@/views/Leave/LeaveList.vue"),
          props: { title: "假別管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "LeaveList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/LeaveView/:companyId/:companyLeaveId/:companyLeaveHistoryId",
          name: "LeaveView",
          component: () => import("@/views/Leave/LeaveView.vue"),
          props: { title: "假別管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "LeaveList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/LeaveEdit/:companyId/:companyLeaveId/:companyLeaveHistoryId",
          name: "LeaveEdit",
          component: () => import("@/views/Leave/LeaveEdit.vue"),
          props: { title: "假別管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "LeaveList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/LeaveAdd/:companyId/:companyLeaveId/:companyLeaveHistoryId",
          name: "LeaveAdd",
          component: () => import("@/views/Leave/LeaveAdd.vue"),
          props: { title: "假別管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "LeaveList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/LeaveHistory/:companyId/:companyLeaveId/:companyLeaveHistoryId",
          name: "LeaveHistory",
          component: () => import("@/views/Leave/LeaveHistory.vue"),
          props: { title: "假別管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "LeaveList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PayrollList",
          name: "PayrollList",
          component: () => import("@/views/Payroll/PayrollList.vue"),
          props: { title: "薪資條管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "PayrollList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/PasswordChange",
          name: "PasswordChange",
          component: () => import("@/views/Account/PasswordChange.vue"),
          props: { title: "修改密碼" },
          beforeEnter: (to, from, next) => {
            showHeadName(to.name);
            next();
          },
        },
        {
          path: "/PayrollView/:ids/:type",
          name: "PayrollView",
          component: () => import("@/views/Payroll/PayrollView.vue"),
          props: { title: "查看" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "PayrollList"
            );
            showHeadName(to.name);
            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/SalaryList",
          name: "SalaryList",
          component: () => import("@/views/Salary/SalaryList.vue"),
          props: { title: "薪調紀錄" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "SalaryList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/SalarySetting/:companyId/:employeeId/:isView",
          name: "SalarySetting",
          component: () => import("@/views/Salary/SalarySetting.vue"),
          props: { title: "薪酬資訊" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "SalaryList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/SalarySettingEdit/:companyId/:employeeId/:isView",
          name: "SalarySettingEdit",
          component: () => import("@/views/Salary/SalarySetting.vue"),
          props: { title: "薪酬資訊" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "SalaryList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OptionList",
          name: "OptionList",
          component: () => import("@/views/Option/OptionList.vue"),
          props: { title: "選項管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "OptionList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OptionAdd/:companyId/:systemOptionId",
          name: "OptionAdd",
          component: () => import("@/views/Option/OptionAdd.vue"),
          props: { title: "新增選項" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "OptionList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/OptionEdit/:companyId/:systemOptionId",
          name: "OptionEdit",
          component: () => import("@/views/Option/OptionEdit.vue"),
          props: { title: "編輯選項" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "OptionList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/Announcement",
          name: "Announcement",
          component: () => import("@/views/Announcement/Announcement.vue"),
          props: { title: "公告訊息列表" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Announcement"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/AnnouncementAdd",
          name: "AnnouncementAdd",
          component: () => import("@/views/Announcement/AnnouncementAdd.vue"),
          props: { title: "新增公告訊息" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Announcement"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/AnnouncementEdit/:announcementId",
          name: "AnnouncementEdit",
          component: () => import("@/views/Announcement/AnnouncementEdit.vue"),
          props: { title: "編輯公告訊息" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Announcement"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/AnnouncementView/:announcementId",
          name: "AnnouncementView",
          component: () => import("@/views/Announcement/AnnouncementView.vue"),
          props: { title: "檢視公告訊息" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "Announcement"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/WorkflowRecord/:workflowType",
          name: "WorkflowRecord",
          component: () => import("@/views/Workflow/WorkflowRecord.vue"),
          props: { title: "通知訊息列表" },
          beforeEnter: (to, from, next) => {
            showHeadName(to.name);
            next();
          },
        },
        {
          path: "/EmployeeForLogin",
          name: "EmployeeForLogin",
          component: () => import("@/views/EmployeeForLogin.vue"),
          props: { title: "職員基本資訊" },
          beforeEnter: (to, from, next) => {
            showHeadName(to.name);
            next();
          },
        },
        {
          path: "/SubscriptionList",
          name: "SubscriptionList",
          component: () => import("@/views/Subscription/SubscriptionList.vue"),
          props: { title: "訂閱管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "SubscriptionList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/SubscriptionView/:enterpriseSubscriptionId",
          name: "SubscriptionView",
          component: () => import("@/views/Subscription/SubscriptionView.vue"),
          props: { title: "訂閱管理-檢視" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "SubscriptionList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          // path: "/SubstituteView/:itemId/:fromWorkflow?",
          path: "/SubstituteView",
          name: "SubstituteView",
          component: () => import("@/views/Substitute/SubstituteView.vue"),
          props: { title: "代理人檢視" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "CompensateList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isCreate) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        // Dealer -------
        {
          path: "/DealerManagementList",
          name: "DealerManagementList",
          component: () =>
            import("@/views/DealerManagement/DealerManagementList.vue"),
          props: { title: "經銷商列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerManagementList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerManagementEdit/:dealerId",
          name: "DealerManagementEdit",
          component: () =>
            import("@/views/DealerManagement/DealerManagementEdit.vue"),
          props: { title: "編輯經銷商" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerManagementEdit"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerManagementAdd",
          name: "DealerManagementAdd",
          component: () =>
            import("@/views/DealerManagement/DealerManagementAdd.vue"),
          props: { title: "新增經銷商" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerManagementAdd"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/ContractManagementList/:dealerId",
          name: "ContractManagementList",
          component: () =>
            import("@/views/DealerManagement/ContractManagementList.vue"),
          props: { title: "合約列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "ContractManagementList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/ContractManagementAdd/:dealerId",
          name: "ContractManagementAdd",
          component: () =>
            import("@/views/DealerManagement/ContractManagementAdd.vue"),
          props: { title: "新增合約" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "ContractManagementAdd"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/ContractManagementEdit/:dealerId/:dealerContractId",
          name: "ContractManagementEdit",
          component: () =>
            import("@/views/DealerManagement/ContractManagementEdit.vue"),
          props: { title: "編輯合約" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "ContractManagementEdit"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerBookingList",
          name: "DealerBookingList",
          component: () =>
            import("@/views/DealerBooking/DealerBookingList.vue"),
          props: { title: "客戶名單列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerBookingList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerBookingView/:dealerBookingId",
          name: "DealerBookingView",
          component: () =>
            import("@/views/DealerBooking/DealerBookingView.vue"),
          props: { title: "檢視客戶名單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerBookingView"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerWorkflowList",
          name: "DealerWorkflowList",
          component: () =>
            import("@/views/DealerWorkflow/DealerWorkflowList.vue"),
          props: { title: "申請單列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerWorkflowList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerWorkflowEdit/:type/:apiId",
          name: "DealerWorkflowEdit",
          component: () =>
            import("@/views/DealerWorkflow/DealerWorkflowEdit.vue"),
          props: { title: "審核申請單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerWorkflowEdit"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerShipmentList",
          name: "DealerShipmentList",
          component: () =>
            import("@/views/DealerShipment/DealerShipmentList.vue"),
          props: { title: "出貨列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerShipmentList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerShipmentList",
          name: "DealerShipmentList",
          component: () =>
            import("@/views/DealerShipment/DealerShipmentList.vue"),
          props: { title: "出貨列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerShipmentList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerShipmentEdit/:dealerShipmentId",
          name: "DealerShipmentEdit",
          component: () =>
            import("@/views/DealerShipment/DealerShipmentEdit.vue"),
          props: { title: "編輯出貨" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerShipmentEdit"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerShipmentAdd",
          name: "DealerShipmentAdd",
          component: () =>
            import("@/views/DealerShipment/DealerShipmentAdd.vue"),
          props: { title: "新增出貨" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerShipmentAdd"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerAnnouncementList",
          name: "DealerAnnouncementList",
          component: () =>
            import("@/views/DealerAnnouncement/DealerAnnouncementList.vue"),
          props: { title: "最新消息列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerAnnouncementList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerAnnouncementEdit/:dealerAnnouncementId",
          name: "DealerAnnouncementEdit",
          component: () =>
            import("@/views/DealerAnnouncement/DealerAnnouncementEdit.vue"),
          props: { title: "編輯最新消息" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerAnnouncementEdit"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerAnnouncementAdd",
          name: "DealerAnnouncementAdd",
          component: () =>
            import("@/views/DealerAnnouncement/DealerAnnouncementAdd.vue"),
          props: { title: "新增最新消息" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerAnnouncementAdd"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerDownloadList",
          name: "DealerDownloadList",
          component: () =>
            import("@/views/DealerDownload/DealerDownloadList.vue"),
          props: { title: "下載專區列表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerDownloadList"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerDownloadEdit/:dealerDownloadId",
          name: "DealerDownloadEdit",
          component: () =>
            import("@/views/DealerDownload/DealerDownloadEdit.vue"),
          props: { title: "編輯下載專區" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerDownloadEdit"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/DealerDownloadAdd",
          name: "DealerDownloadAdd",
          component: () =>
            import("@/views/DealerDownload/DealerDownloadAdd.vue"),
          props: { title: "新增下載專區" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "DealerAnnouncementAdd"
          //   );
          //   showHeadName(to.name);
          //   if (target) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/CarList",
          name: "CarList",
          component: () => import("@/views/Car/CarList.vue"),
          props: { title: "車型管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarAdd",
          name: "CarAdd",
          component: () => import("@/views/Car/CarAdd.vue"),
          props: { title: "車型管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarEdit/:id",
          name: "CarEdit",
          component: () => import("@/views/Car/CarEdit.vue"),
          props: { title: "車型管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarList"
            );
            showHeadName(to.name);
            if (target.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarRentList",
          name: "CarRentList",
          component: () => import("@/views/Car/CarRentList.vue"),
          props: { title: "使用狀態" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarRentList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarRentInsetList",
          name: "CarRentInsetList",
          component: () => import("@/views/Car/CarRentInsetList.vue"),
          props: { title: "借還登記" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarRentList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarRentAdd/:y?/:m?/:d?",
          name: "CarRentAdd",
          component: () => import("@/views/Car/CarRentAdd.vue"),
          props: { title: "借還登記新增" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarRentList"
            );
            showHeadName(to.name);

            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarRentView/:id",
          name: "CarRentView",
          component: () => import("@/views/Car/CarRentView.vue"),
          props: { title: "借還登記檢視", isView: true },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarRentList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarRentView_Cancel/:id_rent/:id_cancel",
          name: "CarRentView_Cancel",
          component: () => import("@/views/Car/CarRentView_Cancel.vue"),
          props: { title: "註銷檢視", isView: true },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarRentList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarRentDrop/:id",
          name: "CarRentDrop",
          component: () => import("@/views/Car/CarRentDrop.vue"),
          props: { title: "借還登記" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarRentList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/CarUsingList",
          name: "CarUsingList",
          component: () => import("@/views/Car/CarUsingList.vue"),
          props: { title: "車用狀況" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "CarUsingList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        // Meeting -------
        {
          path: "/MeetingInsetList",
          name: "MeetingInsetList",
          component: () => import("@/views/Meeting/MeetingInsetList.vue"),
          props: { title: "租借登記" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingAdd/:y?/:m?/:d?",
          name: "MeetingAdd",
          component: () => import("@/views/Meeting/MeetingAdd.vue"),
          props: { title: "租借登記" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingView/:itemId/:cancelId?",
          name: "MeetingView",
          component: () => import("@/views/Meeting/MeetingView.vue"),
          props: { title: "租借登記" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingList",
          name: "MeetingList",
          component: () => import("@/views/Meeting/MeetingList.vue"),
          props: { title: "使用狀態" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingList"
            );
            showHeadName(to.name);
            if (target) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingRoomList",
          name: "MeetingRoomList",
          component: () => import("@/views/MeetingRoom/MeetingRoomList.vue"),
          props: { title: "會議室管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingRoomList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingRoomAdd",
          name: "MeetingRoomAdd",
          component: () => import("@/views/MeetingRoom/MeetingRoomAdd.vue"),
          props: { title: "會議室管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingRoomList"
            );
            showHeadName(to.name);
            if (target?.isCreate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingRoomEdit/:itemId",
          name: "MeetingRoomEdit",
          component: () => import("@/views/MeetingRoom/MeetingRoomEdit.vue"),
          props: { title: "會議室管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingRoomList"
            );
            showHeadName(to.name);
            if (target?.isUpdate) next();
            else toNotFound404(to, from, next);
          },
        },
        {
          path: "/MeetingRoomView/:itemId",
          name: "MeetingRoomView",
          component: () => import("@/views/MeetingRoom/MeetingRoomView.vue"),
          props: { title: "會議室管理" },
          beforeEnter: (to, from, next) => {
            const target = routeInfo.value.find(
              (item) => item?.menuName === "MeetingRoomList"
            );
            showHeadName(to.name);
            if (target?.isRead) next();
            else toNotFound404(to, from, next);
          },
        },
        // 惠加進銷存 ---------
        {
          path: "/HJ_StockMgmt/WorkOrder/WorkOrderList",
          name: "WorkOrderList",
          component: () =>
            import("@/views/HJ_StockMgmt/WorkOrder/WorkOrderList.vue"),
          props: { title: "派工單管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/WorkOrder/WorkOrderAdd",
          name: "WorkOrderAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/WorkOrder/WorkOrderAdd.vue"),
          props: { title: "派工單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/WorkOrder/WorkOrderEdit/:itemId",
          name: "WorkOrderEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/WorkOrder/WorkOrderAdd.vue"),
          props: { title: "派工單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/WorkOrder/WorkOrderView/:itemId",
          name: "WorkOrderView",
          component: () =>
            import("@/views/HJ_StockMgmt/WorkOrder/WorkOrderView.vue"),
          props: { title: "派工單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/OrderList",
          name: "OrderList",
          component: () => import("@/views/HJ_StockMgmt/Order/OrderList.vue"),
          props: { title: "訂單管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/ProductionPlanEdit/:itemId",
          name: "ProductionPlanEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/ProductionPlanEdit.vue"),
          props: { title: "生產進度計畫表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/ProductionPlanView/:itemId",
          name: "ProductionPlanView",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/ProductionPlanView.vue"),
          props: { title: "生產進度計畫表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/OrderControlAdd",
          name: "OrderControlAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/OrderControlAdd.vue"),
          props: { title: "客戶訂單管制表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/OrderControlEdit/:itemId",
          name: "OrderControlEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/OrderControlEdit.vue"),
          props: { title: "客戶訂單管制表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/OrderControlView/:itemId",
          name: "OrderControlView",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/OrderControlView.vue"),
          props: { title: "客戶訂單管制表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/ContractReviewEdit/:itemId",
          name: "ContractReviewEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/ContractReviewEdit.vue"),
          props: { title: "合約審查表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Order/ContractReviewView/:itemId",
          name: "ContractReviewView",
          component: () =>
            import("@/views/HJ_StockMgmt/Order/ContractReviewView.vue"),
          props: { title: "合約審查表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/IncomingList",
          name: "IncomingList",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/IncomingList.vue"),
          props: { title: "進料管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/MaterialInspectionAdd",
          name: "MaterialInspectionAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/MaterialInspectionAdd.vue"),
          props: { title: "進料檢驗紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/MaterialInspectionEdit/:itemId",
          name: "MaterialInspectionEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/MaterialInspectionEdit.vue"),
          props: { title: "進料檢驗紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/MaterialInspectionView/:itemId",
          name: "MaterialInspectionView",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/MaterialInspectionView.vue"),
          props: { title: "進料檢驗紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/PurchaseOrderAdd",
          name: "PurchaseOrderAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/PurchaseOrderAdd.vue"),
          props: { title: "採購單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/PurchaseOrderEdit/:itemId",
          name: "PurchaseOrderEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/PurchaseOrderEdit.vue"),
          props: { title: "採購單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/PurchaseOrderView/:itemId",
          name: "PurchaseOrderView",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/PurchaseOrderView.vue"),
          props: { title: "採購單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/OutsourceOrderAdd",
          name: "OutsourceOrderAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/OutsourceOrderAdd.vue"),
          props: { title: "委外加工單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/OutsourceOrderEdit/:itemId",
          name: "OutsourceOrderEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/OutsourceOrderEdit.vue"),
          props: { title: "委外加工單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Incoming/OutsourceOrderView/:itemId",
          name: "OutsourceOrderView",
          component: () =>
            import("@/views/HJ_StockMgmt/Incoming/OutsourceOrderView.vue"),
          props: { title: "委外加工單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Warehouse/WarehouseList",
          name: "WarehouseList",
          component: () =>
            import("@/views/HJ_StockMgmt/Warehouse/WarehouseList.vue"),
          props: { title: "倉儲管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Warehouse/RawMaterialAdd",
          name: "RawMaterialAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Warehouse/RawMaterialAdd.vue"),
          props: { title: "成品庫存卡" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Warehouse/RawMaterialView/:itemId",
          name: "RawMaterialView",
          component: () =>
            import("@/views/HJ_StockMgmt/Warehouse/RawMaterialView.vue"),
          props: { title: "成品庫存卡" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Warehouse/FinishedInventoryAdd",
          name: "FinishedInventoryAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Warehouse/FinishedInventoryAdd.vue"),
          props: { title: "原料庫存卡" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Warehouse/FinishedInventoryView/:itemId",
          name: "FinishedInventoryView",
          component: () =>
            import("@/views/HJ_StockMgmt/Warehouse/FinishedInventoryView.vue"),
          props: { title: "原料庫存卡" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/SystemList",
          name: "SystemList",
          component: () => import("@/views/HJ_StockMgmt/System/SystemList.vue"),
          props: { title: "系統管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/MaterialMgmtAdd",
          name: "MaterialMgmtAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/System/MaterialMgmtAdd.vue"),
          props: { title: "物料管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/MaterialMgmtEdit/:itemId",
          name: "MaterialMgmtEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/System/MaterialMgmtEdit.vue"),
          props: { title: "物料管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/MaterialMgmtView/:itemId",
          name: "MaterialMgmtView",
          component: () =>
            import("@/views/HJ_StockMgmt/System/MaterialMgmtView.vue"),
          props: { title: "物料管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/SupplierMgmtAdd",
          name: "SupplierMgmtAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/System/SupplierMgmtAdd.vue"),
          props: { title: "供應商管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/SupplierMgmtView/:itemId",
          name: "SupplierMgmtView",
          component: () =>
            import("@/views/HJ_StockMgmt/System/SupplierMgmtView.vue"),
          props: { title: "供應商管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/PersonnelMgmtAdd",
          name: "PersonnelMgmtAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/System/PersonnelMgmtAdd.vue"),
          props: { title: "人員管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/PersonnelMgmtEdit/:itemId",
          name: "PersonnelMgmtEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/System/PersonnelMgmtEdit.vue"),
          props: { title: "人員管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/FormMgmtAdd",
          name: "FormMgmtAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/System/FormMgmtAdd.vue"),
          props: { title: "表單管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/System/FormMgmtEdit/:itemId",
          name: "FormMgmtEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/System/FormMgmtEdit.vue"),
          props: { title: "表單管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/MachineTestList",
          name: "MachineTestList",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/MachineTestList.vue"),
          props: { title: "檢驗紀錄管理" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/MachineTestEdit/:itemId",
          name: "MachineTestEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/MachineTestEdit.vue"),
          props: { title: "整機測試紀錄表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/MachineTestView/:itemId",
          name: "MachineTestView",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/MachineTestView.vue"),
          props: { title: "整機測試紀錄表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/PerformanceReportEdit/:itemId",
          name: "PerformanceReportEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/PerformanceReportEdit.vue"),
          props: { title: "整機性能指標檢測報告" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/PerformanceReportView/:itemId",
          name: "PerformanceReportView",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/PerformanceReportView.vue"),
          props: { title: "整機性能指標檢測報告" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/LeakageTestEdit/:itemId",
          name: "LeakageTestEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/LeakageTestEdit.vue"),
          props: { title: "漏電流測試表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/LeakageTestView/:itemId",
          name: "LeakageTestView",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/LeakageTestView.vue"),
          props: { title: "漏電流測試表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/FinalInspectionEdit/:itemId",
          name: "FinalInspectionEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/FinalInspectionEdit.vue"),
          props: { title: "最終檢驗紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Inspection/FinalInspectionView/:itemId",
          name: "FinalInspectionView",
          component: () =>
            import("@/views/HJ_StockMgmt/Inspection/FinalInspectionView.vue"),
          props: { title: "最終檢驗紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/DeliveryList",
          name: "DeliveryList",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/DeliveryList.vue"),
          props: { title: "出貨單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/DeliveryNoteAdd",
          name: "DeliveryNoteAdd",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/DeliveryNoteAdd.vue"),
          props: { title: "出貨單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/DeliveryNoteEdit/:itemId",
          name: "DeliveryNoteEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/DeliveryNoteEdit.vue"),
          props: { title: "出貨單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/DeliveryNoteView/:itemId",
          name: "DeliveryNoteView",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/DeliveryNoteView.vue"),
          props: { title: "出貨單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/InstallationRecordEdit/:itemId",
          name: "InstallationRecordEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/InstallationRecordEdit.vue"),
          props: { title: "安裝紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/InstallationRecordView/:itemId",
          name: "InstallationRecordView",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/InstallationRecordView.vue"),
          props: { title: "安裝紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/BatchRecordEdit/:itemId",
          name: "BatchRecordEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/BatchRecordEdit.vue"),
          props: { title: "生產批次紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/BatchRecordView/:itemId",
          name: "BatchRecordView",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/BatchRecordView.vue"),
          props: { title: "生產批次紀錄單" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/MaintenanceRecordList",
          name: "MaintenanceRecordList",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/MaintenanceRecordList.vue"),
          props: { title: "客戶維修服務紀錄表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/MaintenanceRecordEdit/:itemId",
          name: "MaintenanceRecordEdit",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/MaintenanceRecordEdit.vue"),
          props: { title: "客戶維修服務紀錄表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
        {
          path: "/HJ_StockMgmt/Delivery/MaintenanceRecordView/:itemId",
          name: "MaintenanceRecordView",
          component: () =>
            import("@/views/HJ_StockMgmt/Delivery/MaintenanceRecordView.vue"),
          props: { title: "客戶維修服務紀錄表" },
          // beforeEnter: (to, from, next) => {
          //   const target = routeInfo.value.find(
          //     (item) => item?.menuName === "OrderControlList"
          //   );
          //   showHeadName(to.name);
          //   if (target?.isRead) next();
          //   else toNotFound404(to, from, next);
          // },
        },
      ],
    },
    {
      // ↓↓↓ 404 是遇到無法匹配的網址會跳轉到這一頁來，請放在最後一個。
      path: "/:pathMatch(.*)",
      name: "NotFound",
      component: () => import("../views/NotFound404.vue"),
    },
  ],

  scrollBehavior(to, from) {
    if (router?.options.history.location?.split("/").at(-1) !== "Login") {
      localStorage.setItem("lastPage", router.options.history.location);
    }

    if (to.matched[1] === from.matched[1]) return;
    else {
      if (to.hash && to.hash !== "#") {
        return {
          el: to.hash,
          top: 16,
        };
      } else return { top: 0 };
    }
  },
});

export default router;
